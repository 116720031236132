import { api } from '../utils/api';

/** 获取用户对应的全部厂商声望等级信息  */
export async function getUserReputationList(data) {
    return api('/web/task/grade/user', data, 'POST', false);
}

/** 获取全部声望等级 */
export async function getReputationAll(data) {
    return api('/web/task/grade/all', data, 'POST', false, true, false);
}

// 获取声望记录列表
export async function getReputationLogList(data) {
    return api('/web/task/value/logs', data, 'POST', true);
}

// 获取用户声望值
export async function getValueUser(data) {
    return api('/web/task/value/user', data, 'POST', true, false, false);
}

// 获取用户全部声望值
export async function getValueAll(data) {
    return api('/web/task/value/all', data, 'POST', true, false, false);
}
